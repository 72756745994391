import React,{useEffect} from 'react'
import Sweets from './Sweets'


function KukuNamkeen() {
    useEffect(() => {
        window.scrollTo(0,0);
         },[])
    return (
        <>
        <div>
            <Sweets /> 
        </div>  
</>

    )
}

export default KukuNamkeen
