import { getToken } from "../utils/getToken";
import { defaultApi } from "./api";
import { USER_DETAILS_BY_USER_TOKEN, USER_UPDATE_USER_DETAILS } from "./constant";
export function getUserDetails() {
    const token = getToken();
    return defaultApi.get(USER_DETAILS_BY_USER_TOKEN, { headers: {"token" : token} });
  }
  
  export function updateUserProfile(formData) {
    const token = getToken();
    return defaultApi.post(USER_UPDATE_USER_DETAILS, formData, { headers: {"token" : token} });
  }

  export function addContact(data) {
    return defaultApi.post(`/api/admin/addContact`, data);
  }