import React, { useState, useEffect } from "react";
import styles from "./Herosection.module.css";
import Hero from "../Hero/Hero";
import Herom from "../Hero/Herom";
import Third from "../ThirdSection/Third";
import Fourth from "../FourthSection/Fourth";
import Fifth from "../FifthSection/Fifth";
import Sixth from "../SixthSection/Sixth";
import Seventh from "../SeventhSection/Seventh";
import "./Herosection.css";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import MainContent from "./MainContent";
import { getToken } from "../../utils/getToken";
import Header from "../Header/Header";
function Herosection() {
  const [isOpen, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 750);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1000);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const localStore = getToken();


  return (
    <>
   {/* {isMobile ? <Herom /> : <Hero />} */}
<Hero/>
      <MainContent />

    </>
  );
}

export default Herosection;
