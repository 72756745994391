import React from 'react'
import { useQuery } from "@tanstack/react-query";
import { getProducts } from '../../services/productService';

const Second = ({perksData}) => {
  return (
    <div>
        <div className="my-24 ">
        <div className="items-center gap-5 px-10 w-full grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
          {
                perksData?.map(item =>

                    <div  key={item.perk_id} className="image-container  flex text-center items-center justify-center ">
                    <img
                      src={item.perk_image}
                      className=""
                      alt=""
                      class=""
                      style={{ display: "inline", width:"130px", height:"130px" }}
                    />
                    <h4 class="text-sm font-semibold">{item.title}</h4>
                    {/* <p style={{color:"grey"}} className='text-sm'>{item.subtitle}</p> */}
                    {/* <img
                      src="/Image/Arrow.svg"
                      alt="error"
                      className="w-8 items-center"
                      style={{ display: "initial" }}
                    /> */}
                  </div>
                
                )}
        </div>
      </div>
    </div>
  )
}

export default Second
