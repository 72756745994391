import { defaultApi } from "./api";
export async function getHeaderData({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/top_sections`);
  return res.data.data;
}

export async function getLogo({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/getConfig`);
  return res.data.data;
  // return  res.data.company_logo = `${IMAGE_BASE_URL}/${i.company_logo}`
}

