import React from "react";
import img1 from "./step1.jpg";
import img2 from "./step2.jpg";
import img3 from "./step3.jpg";
import img4 from "./step4.jpg";

function Makingprocess() {
  return (
    <div>
      <div className="mt-28  px-4 md:px-8 text-center mb-5">
        <h5
          className="text-center  text-3xl uppercase"
          style={{
            fontFamily: "Tenor Sans, sans-serif",
          }}
        >
          Making Process
        </h5>
        <p className="mt-5 text-2xl">
          We make this authentic namkeen's following this 4 steps.
        </p>
      </div>
      <div>
        <div className="w-full flex flex-col mt-5 p-5 lg:flex-row md:flex-row">
          <div
            className="w-full lg:w-1/2 md:w-1/2 flex "
          >
            <img src={img1} alt="image" className="rounded" />
          </div>
          <div className="w-full lg:w-1/2 md:w-1/2 flex flex-col  mt-2 p-2 lg:p-5 md:p-5 ">
            <div className="font-semibold text-2xl lg:text-3xl md:text-3xl text-center">
              Center Filling of Kachori
            </div>
            <p className="font-normal text-xl g:text-2xl md:text-2xl mt-2">
              {" "}
              First of all the center fill of kachori is made from gram flour
              (Ganthiya powder), then we mix different spices, refined sugar
              with mineral water and the mixture is ready. we make small balls
              and this ball covered with kachori outer layer.
              <br />
              <i>We not use any kind of preservatives to make this mixture.</i>
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col-reverse mt-0 lg:mt-5 md:mt-5 p-5 lg:flex-row md:flex-row">
          
          <div className="w-full lg:w-1/2 md:w-1/2 flex flex-col  mt-2 p-2 lg:p-5 md:p-5 ">
            <div className="font-semibold text-2xl lg:text-3xl md:text-3xl text-center">
            Outer layer of kachori
            </div>
            <p className="font-normal text-xl g:text-2xl md:text-2xl mt-2">
              {" "}
              For outer layer of kachori we used pure refined wheat flour. from this flour we make dough and one by one make the shape of kachori (Round or Chapti) with fill of first steps of mixture we made before.
            </p>
          </div>
          <div
            className="w-full lg:w-1/2 md:w-1/2 flex "
          >
            <img src={img2} alt="image" className="rounded" />
          </div>
        </div>
        <div className="w-full flex flex-col mt-0 lg:mt-5 md:mt-5 p-5 lg:flex-row md:flex-row">
          <div
            className="w-full lg:w-1/2 md:w-1/2 flex "
          >
            <img src={img3} alt="image" className="rounded" />
          </div>
          <div className="w-full lg:w-1/2 md:w-1/2 flex flex-col  mt-2 p-2 lg:p-5 md:p-5 ">
            <div className="font-semibold text-2xl lg:text-3xl md:text-3xl text-center">
              Final Step
            </div>
            <p className="font-normal text-xl g:text-2xl md:text-2xl mt-2">
              {" "}
              Final procedure is to fry the Kachori in oil.
<i>here we not use a palm oil for frying process</i> we only use " Pure Cottonseed oil " for this process
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col-reverse mt-0 lg:mt-5 md:mt-5 p-5 lg:flex-row md:flex-row">
          
          <div className="w-full lg:w-1/2 md:w-1/2 flex flex-col  mt-2 p-2 lg:p-5 md:p-5 ">
            <div className="font-semibold text-2xl lg:text-3xl md:text-3xl text-center">
            Packaging
            </div>
            <p className="font-normal text-xl g:text-2xl md:text-2xl mt-2">
              {" "}
              Here our products delivered in premium packaging. all products are available in safe and sealed pack.       </p>
          </div>
          <div
            className="w-full lg:w-1/2 md:w-1/2 flex "
          >
            <img src={img4} alt="image" className="rounded" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Makingprocess;
