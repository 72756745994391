import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCarts,
  removeCarts,
  updateCarts,
} from "../../services/productService";
import useLogin from "../Hooks/useLogin";
import FormatPrice from "../../FormatPrice";
import { getLocalUserId } from "../../utils/getLocalUserId";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { FaRegAddressCard } from "react-icons/fa6";
import { MdOutlinePayment } from "react-icons/md";
import { getToken } from "../../utils/getToken";

function Cart() {
  const localStore = getToken();

  const { isLogin } = useLogin();
  //   const { selectedCountrySymbol } = useContext(CountryContext);
  const queryClient = useQueryClient();
  const user_id = getLocalUserId();

  const { data: cartData } = useQuery({
    queryKey: ["get-cart", { user_id }],
    queryFn: getCarts,
  });

  const mutateUpdateCart = useMutation({
    mutationKey: ["update-cart"],
    mutationFn: updateCarts,
    onSuccess: () => {
      const stored = JSON.parse(localStorage.getItem("carts") || "{}");
      setCartItem(stored);
      queryClient.invalidateQueries("get-cart");
    },
  });
  const mutateRemoveCart = useMutation({
    mutationKey: ["remove-cart"],
    mutationFn: removeCarts,
    onSuccess: () => {
      queryClient.invalidateQueries("get-cart");
    },
  });
  const [cartItems, setCartItem] = useState({});
  const carts = Object.values(cartData?.data?.data || {});

  useEffect(() => {
    window.scrollTo(0,0);
     },[])

  const updateItem = (item, quantity) => {
    const localCart = JSON.parse(localStorage.getItem("carts") || "{}");
    if (quantity > 0) {
      const newCart = {
        ...localCart,
        [item.product_id]: {
          ...localCart[item.product_id],
          quantity,
        },
      };
      localStorage.setItem("carts", JSON.stringify(newCart));
      mutateUpdateCart.mutate({ cart_id: item.id, quantity });
    } else {
      mutateRemoveCart.mutate({ cart_id: item.id });
    }
  };

  const totalPrice = carts.reduce(
    (acc, cart) => acc + cart.price * cart.quantity,
    0
  );
  const shipping = 0;
  const qualityCheck = 0;
  return (
    <>
      <div className=" mt-24 lg:mt-24 container mx-auto px-4 sm:px-6 lg:px-8 md:mt-24 sm:mt-24 flex flex-col md:flex-row lg:flex-row xl:flex-row items-center justify-center gap-0 lg:gap-24 xl:gap-24 w-full">
        {carts.length > 0 ? (
          <>
            <div className="flex flex-col w-full  md:mr-3">
              {carts?.map((item) => (
                <div className="w-full mb-5">
                  <div className="flex flex-col space-y-5">
                    <div>
                      <div
                        className="flex-col shadow rounded-2xl flex px-2 py-2 items-center justify-between"
                        style={{ border: "1px solid #a5a5a5" }}
                      >
                      <div className=" flex w-full ">
                      <div className="sm:text-sm">
                              <h4
                                className="chrome sm:text-sm font-medium"
                                style={{ color: "#1E1E1E" }}
                              >
                                {item?.product_name}
                              </h4>
                            </div>
                      </div>


<div className="flex align-middle w-full space-x-4">


                        <div className="flex flex-grow flex-col">
                          <div className="flex items-center justify-between">
                          <img
                          src={item?.thumbnail_image}
                          className="w-20 h-20 rounded-lg mr-4"
                          alt={item?.product_name}
                        />

                            <div className="flex items-center space-x-2 bg-[#F3F3F3] rounded-2xl px-4 py-2">
                              <button
                                className="cursor-pointer w-6 h-6"
                                style={{ color: "#952F8A" }}
                                onClick={() =>
                                  updateItem(item, item.quantity - 1)
                                }
                              >
                                -
                              </button>
                              <span
                                className="mr-2"
                                style={{ color: "#952F8A" }}
                              >
                                {item?.quantity}
                              </span>
                              <button
                                className="cursor-pointer w-6 h-6"
                                style={{ color: "#952F8A" }}
                                onClick={() =>
                                  updateItem(item, item.quantity + 1)
                                }
                              >
                                +
                              </button>
                            </div>
                            <div className="flex gap-10">
                              <h5 className="text-lg ">
                                <FormatPrice price={item.price} />
                              </h5>
                              <button
                                className="cursor-pointer"
                                onClick={() =>
                                  mutateRemoveCart.mutate({ cart_id: item.id })
                                }
                              >
                                <img
                                  src="/Image/Trash.svg"
                                  alt="Delete"
                                  className="w-8 h-8 cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="w-full lg:w-3/4 xl:w-3/4 mb-5"
              style={{ marginLeft: "auto" }}
            >
              <div className=" p-0">
                <div
                  className="bg-white shadow rounded-lg p-4 justify-center flex mb-4"
                  style={{
                    border: "1px solid #a5a5a5",
                    borderRadius: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="justiy-center ">
                    <p style={{ display: "flex", flexDirection: "row" }}>
                      <span
                        style={{
                          color: "rgb(0,179,149)",
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <HiOutlineShoppingCart size={25} /> Cart
                      </span>
                      <span
                        style={{
                          color: "gray",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        ------{" "}
                        <FaRegAddressCard
                          size={25}
                          style={{ marginLeft: "3px", marginRight: "4px" }}
                        />{" "}
                        Address------{" "}
                        <MdOutlinePayment
                          size={25}
                          style={{ marginLeft: "3px", marginRight: "1px" }}
                        />
                        Payment
                      </span>
                    </p>
                  </div>
                </div>

                <div
                  className="bg-white shadow rounded-lg p-4"
                  style={{ border: "1px solid #a5a5a5", borderRadius: "10px" }}
                >
                  {carts?.map((item) => (
                    <div className="flex items-center justify-between mb-2">
                      <span>{item?.product_name}</span>
                      <span>
                        <FormatPrice price={item.price * item.quantity} />
                      </span>
                    </div>
                  ))}
                  <hr className="border-t border-gray-400 my-4" />
                  <div className="mb-4">
                    <div className="flex items-center justify-between mb-2">
                      <span>Subtotal</span>
                      <span>
                        <FormatPrice
                          price={carts.reduce(
                            (acc, cart) => acc + cart.price * cart.quantity,
                            0
                          )}
                        />
                      </span>
                    </div>
                    {/* <div className="flex items-center justify-between mb-2">
                      <span>Shipping</span>
                      <span>
                        <FormatPrice price={shipping} />
                      </span>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <span>Quality Check</span>
                      <span>
                        <FormatPrice price={qualityCheck} />
                      </span>
                    </div> */}
                  </div>

                  {/* <div className="flex items-center justify-between mb-4 font-bold">
                    <span>Total (Tax incl.)</span>
                    <span>
                      <FormatPrice
                        price={totalPrice + shipping + qualityCheck}
                      />
                    </span>
                  </div> */}
                  <div className="">
                    <Link to={localStore ? "/checkout" : "/sign-up"}>
                      {" "}
                      <button className="bg-[#952F8A] text-white rounded-lg text-xl py-3 px-4 w-full mt-4">
                        Proceed to Pay
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center">
            <strong className=" mt-2 text-2xl ">
              Your cart is currently empty!
            </strong>
            <button
              className="rounded text-white p-2 w-48 mt-4"
              style={{ backgroundColor: "rgb(162,0,141)" }}
            >
              <Link to="/sweets">Continue Shopping</Link>
            </button>
          </div>
        )}
      </div>
      <div className="mt-32"></div>
    </>
  );
}

export default Cart;
