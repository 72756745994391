import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

function Info() {
  const phoneNumber = "+91 9427265661";
  const email = "kukunamkeen65@gmail.com";

  return (
    <div className="flex w-full flex-row border py-5 mt-5 text-white" style={{backgroundColor: "rgb(162,7,141)", overflowX:"hidden"}}>
      <div className="flex w-1/2 justify-center py-2">
        <div className="flex flex-col text-center ">
        <div className="justify-center flex p-2 pt-3"><FaPhoneAlt size={20}/></div>
            <h2 className="text-l font-semibold lg:text-xl md:text-xl">Call Us Now</h2>
            <div>
                <a className="text-sm lg:text-xl md:text-xl" href={`tel:${phoneNumber}`}>+91 9427265661<br/> +91 8780266042</a>
            </div>
        </div>
      </div>
      <div className="flex w-1/2 justify-center py-2">
        <div className="flex flex-col text-center">
        <div className="justify-center flex p-2"><IoMdMail size={22}/></div>

            <h2 className="text-l font-semibold lg:text-xl md:text-xl">E-Mail Us</h2>
            <div>
            <a className="text-sm lg:text-xl md:text-xl" href={`mailto:${email}`}>{email}</a>
            </div>
        </div>
      </div>

      
    </div>
  );
}

export default Info;
