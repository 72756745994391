import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function Payment() {
  const [search] = useSearchParams();
  const encryptedData = search?.get("encryptedData");
  const accessCode = search?.get("accessCode");

  useEffect(() => {
    if (accessCode && encryptedData) {
      setTimeout(() => {
        document.redirect.submit();
      }, 500);
    }
  }, [encryptedData, accessCode]);

  return (
    <div style={{ marginTop: "100px" }}>
      Loading...
      {/* For production payment url will be changed. Please Ask Payment gateway for testign and production url*/}
      <form
        id="nonseamless"
        method="post"
        name="redirect"
        action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction "
      >
        <input
          type="hidden"
          id="encRequest"
          name="encRequest"
          value={encryptedData}
        />
        <input
          type="hidden"
          name="access_code"
          id="access_code"
          value={accessCode}
        />
      </form>
    </div>
  );
}
