import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserDetails, updateUserProfile } from '../../services/Users';
import { ToastContainer, toast } from 'react-toastify';

import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Typography,
} from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import { LogIn, ShoppingBasket, UserCircle2, LogOut } from "lucide-react";
import { getToken } from "../../utils/getToken";

export function ProfileMenu() {
  const navigate = useNavigate()

  const localStore = getToken()

  const handleLogout = () =>{
    localStorage.removeItem('token');
    navigate('/')
  }

  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);
  const { data: user, isLoading, error } = useQuery({
    queryFn: getUserDetails,
    queryKey: ['user_profile_info'],
  });

  const mutateUserProfile = useMutation({
    mutationFn: updateUserProfile,
    mutationKey: ['upload_user_profile'],
    onSuccess: (data) => {
      if (data?.data?.result === 1) {
        queryClient.invalidateQueries('user_profile_info');
        toast(data?.data?.msg);
      }
    },
    onError: (e) => {
      console.error(e);
      toast('Something went wrong');
    },
  });
  return (
    <Menu>
      <MenuHandler>
        <Avatar
          variant="circular"
          alt="tania andrew"
          className="cursor-pointer rounded-full w-8 h-8 "
          src={
            user?.data?.data?.profile_image ||
            'https://img.freepik.com/premium-photo/marine-biologist-digital-avatar-generative-ai_934475-9003.jpg?w=740'
          }        />
      </MenuHandler>
      <MenuList className="space-y-5 bg-[#FBC44B] z-10">
        <MenuItem className="flex items-center gap-2 ">
          <UserCircle2 className="" style={{ color: "white" }} />
          <Typography variant="small" className="font-semibold">
            <Link to="/profile">
              <button className="hover:text-orange-500">My Profile</button>
            </Link>
          </Typography>
        </MenuItem>

        <hr className="border-blue-gray-50"></hr>
        <MenuItem className="flex items-center gap-2">
          <ShoppingBasket style={{ color: "white" }} />
          <Typography variant="small" className="font-semibold">
            <Link to="/orders">
              {" "}
              <button className="hover:text-orange-500"> Your Order</button>
            </Link>
          </Typography>
        </MenuItem>

        {
          localStore ?
            <>
              <hr className="my-2 border-blue-gray-50" />
              <MenuItem className="flex items-center gap-3 ">
                <LogOut className=" w-5 h-5" style={{ color: "white" }} />
                <Typography variant="small" className="font-semibold">
                    {" "}
                    <button className=" hover:text-orange-500" onClick={handleLogout}> Logout</button>{" "}
                </Typography>
              </MenuItem>
            </>
            :
            <>
              <hr className=" border-blue-gray-50"></hr>
              <MenuItem className="flex items-center gap-3">
                <LogIn className="w-5 h-5" style={{ color: "white" }} />
                <Typography variant="small" className="font-semibold">
                  <Link to="/register">
                    <button className="hover:text-orange-500">Login</button>
                  </Link>
                </Typography>
              </MenuItem>
            </>



        }


      </MenuList>
    </Menu>
  );
}
