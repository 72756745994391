export const GET_CATEGORY_URL = "/api/product/getCategories";

export const GET_AllCATEGORY_URL ="/api/product/getParentCategories";

export const PRODUCT_DETAILS_URL ="/api/product/getProducts?category_id=1&size=L&status=Active";

export const PRODUCT_GET_STATIC_PAGE_URL = '/api/admin/getStaticPage';
export const USER_DETAILS_BY_USER_TOKEN = '/api/user/getUserDetails';
export const USER_UPDATE_USER_DETAILS = '/api/user/updateProfile';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const IMAGE_BASE_URL = BASE_URL + "/uploads";










