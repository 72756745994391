import React, { useEffect, useState } from "react";

export default function useLogin() {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const token = !!window.localStorage.getItem('token') || false;
    setIsLogin(token);
  }, []);

  return { isLogin, setIsLogin }
}