import React from 'react';
import './Preloader.css';
import loadingVideo from "./Preloader.mp4";

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="logo">
        <video autoPlay loop muted playsInline>
          <source src={loadingVideo} type="video/mp4" />
        </video>
      </div>
  {/*      <div className="text">KUKU Namkeen</div>*/}
      <div className="loading-bar"></div>
    </div>
  );
};

export default Preloader;
