// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCrRg2GtHo3ekJ6iHRTLG6GqmTW6hlscVc",
  authDomain: "kuku-namkeen-625f6.firebaseapp.com",
  projectId: "kuku-namkeen-625f6",
  storageBucket: "kuku-namkeen-625f6.appspot.com",
  messagingSenderId: "1068498943232",
  appId: "1:1068498943232:web:b10934422b3f03652e07e5",
  measurementId: "G-WJFV8YEMEX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth =getAuth(app)
const provider = new GoogleAuthProvider();
export {auth,provider} ;