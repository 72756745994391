import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { getProducts } from '../../services/productService';
import { Link } from 'react-router-dom';

const SearchContent = ({ searchContent, onCloseSearch }) => {
  const [productsShow, setProductsShow] = useState(false);
  const [sortedProductsResults, setSortedProductsResults] = useState([]);
  const { data: products } = useQuery({
    queryKey: ['keyword', searchContent],
    queryFn: getProducts,
  });

  useEffect(() => {
    if (searchContent) {
      const regex = new RegExp(searchContent, 'i');
      const sortedProducts = products?.data?.filter((product) =>
        regex.test(product.product_name)
      );
      setSortedProductsResults(sortedProducts);
      setProductsShow(sortedProducts?.length > 0);
    }
  }, [searchContent, products]);

  const handleProductClick = () => {
    // Close the search box when a product is clicked
    onCloseSearch();
  };

  return (
    <div>
      <div className='w-full'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={8}>
            <div className='flex flex-col gap-5'>
              <h1 className='font-bold'>Products</h1>
              {sortedProductsResults?.map((product) => (
                <ul key={product.id}>
                  <li>
                    {/* Use Link to navigate to the product overview page */}
                    <Link
                      to={`/product/${product.slug}`}
                      className='flex gap-5'
                      onClick={handleProductClick}
                    >
                      {/* <img className='w-1/6' src={product?.thumbnail_image} alt="img" /> */}
                      <span>{product?.product_name}</span>
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
          </Grid>
          {!productsShow && (
            <Grid xs={12}>
              <div>
                <div>
                  <p className='ms-6'>
                    Your search for {' '} &#8243; {searchContent} &#8243; did not
                    yield any results.
                  </p>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default SearchContent;
