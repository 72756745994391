import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);

  // Extract user_id from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('user_id');



  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      toast.info("All fields are required!");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/resetPassword`, {
        new_password: newPassword,
        confirm_password: newPassword,
        user_id: userId,
      },{
        headers:{
          "Content-Type": "multipart/form-data",
        }
      }
      );
// console.log(userId);
      if (response.data.result) {
        toast.success(response.data.msg);
        setResetSuccess(true);
      } else {
        toast.error(response.data.errors);
      }
    } catch (error) {
      toast.error("Password reset failed. Please try again.");
      console.error(error);
    }
  };



  return (
    <>
      <div className="min-h-screen mt-12 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg border shadow-md w-full max-w-md">
          <h5 className="font-extrabold text-center mb-6 text-2xl uppercase" style={{ fontFamily: "Tenor Sans, sans-serif", textDecoration: "underline #FBC44B " }}>
            Reset Password
          </h5>
          <form onSubmit={handleResetPassword} className="space-y-4">

            <div>
              <label htmlFor="newPassword" className="block text-lg font-medium text-gray-700 mb-2">
                New Password:
              </label>
              <input
                type="password"
                id="newPassword"
                placeholder="Enter New Password"
                className="w-full text-black px-4 py-3 rounded-lg border border-gray-300 focus:ring focus:ring-indigo-500"
                value={newPassword}
                onChange={handleNewPasswordChange}
                required
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-lg font-medium text-gray-700 mb-2">
                Confirm Password:
              </label>
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirm Password"
                className="w-full text-black px-4 py-3 rounded-lg border border-gray-300 focus:ring focus:ring-indigo-500"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
            </div>
            {resetSuccess && <p className="text-green-500">Password reset successfully!</p>}
            <button
              type="submit"
              className="bg-[#952F8A] w-full text-white rounded-lg py-2 text-lg font-medium"
            >
              Reset Password
            </button>
          </form>
          <div className="text-center mt-4">
            <Link to="/login" className="text-indigo-600">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default ResetPassword;
