export const getLocalUserId = () => {
  const user_id =
    localStorage.getItem("user_id") ||
    (
      Date.now() +
      Math.floor(Math.random() * Date.now())
        .toString(16)
        .replace(/[A-Za-z]/g, "")).slice(0, 10);
  localStorage.setItem("user_id", user_id);

  return user_id;
};
