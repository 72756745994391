import React, { useState, useEffect } from 'react';
import Footer from '../../Footer/Footer';

function Faq() {

  useEffect(() => {
    window.scrollTo(0,0);
     },[])

  const faqData = [
    {
      question: 'How will my order be delivered to me?',
      answer:
        'Your order will be delivered to your provided shipping address through our standard delivery method. We typically use reliable shipping carriers to ensure a smooth and secure delivery process. The estimated delivery time is usually [mention the estimated delivery time, e.g., 3-5 business days], though this may vary depending on your location and any external factors like holidays. Once your order is shipped, you will receive an email containing tracking information, allowing you to monitor its progress. Please ensure that your delivery address is accurate to avoid any issues. If you have any questions or face any delivery-related concerns, our customer support team is available to assist you. You can reach us at [provide contact details]. If you have specific delivery instructions or requirements, please communicate them during the checkout process or by contacting our customer support team.',
    },
    {
      question: 'What do I need to know?',
      answer:
        'Your order will be delivered to your provided shipping address through our standard delivery method. We typically use reliable shipping carriers to ensure a smooth and secure delivery process. The estimated delivery time is usually [mention the estimated delivery time, e.g., 3-5 business days], though this may vary depending on your location and any external factors like holidays. Once your order is shipped, you will receive an email containing tracking information, allowing you to monitor its progress. Please ensure that your delivery address is accurate to avoid any issues. If you have any questions or face any delivery-related concerns, our customer support team is available to assist you. You can reach us at [provide contact details]. If you have specific delivery instructions or requirements, please communicate them during the checkout process or by contacting our customer support team.',
    },
    {
      question: 'How will I know if my order is placed successfully?',
      answer:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    },
    {
      question: 'How do I check the status of my order?',
      answer:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    },
    {
      question: 'Can I cancel my order?',
      answer:
        'Your order will be delivered to your provided shipping address through our standard delivery method. We typically use reliable shipping carriers to ensure a smooth and secure delivery process. The estimated delivery time is usually [mention the estimated delivery time, e.g., 3-5 business days], though this may vary depending on your location and any external factors like holidays. Once your order is shipped, you will receive an email containing tracking information, allowing you to monitor its progress. Please ensure that your delivery address is accurate to avoid any issues. If you have any questions or face any delivery-related concerns, our customer support team is available to assist you. You can reach us at [provide contact details]. If you have specific delivery instructions or requirements, please communicate them during the checkout process or by contacting our customer support team.',
    },
    {
      question: 'Do you allow exchanges?',
      answer:
        'Your order will be delivered to your provided shipping address through our standard delivery method. We typically use reliable shipping carriers to ensure a smooth and secure delivery process. The estimated delivery time is usually [mention the estimated delivery time, e.g., 3-5 business days], though this may vary depending on your location and any external factors like holidays. Once your order is shipped, you will receive an email containing tracking information, allowing you to monitor its progress. Please ensure that your delivery address is accurate to avoid any issues. If you have any questions or face any delivery-related concerns, our customer support team is available to assist you. You can reach us at [provide contact details]. If you have specific delivery instructions or requirements, please communicate them during the checkout process or by contacting our customer support team.',
    },
    {
      question: 'What are the shipping charges?',
      answer:
        'Your order will be delivered to your provided shipping address through our standard delivery method. We typically use reliable shipping carriers to ensure a smooth and secure delivery process. The estimated delivery time is usually [mention the estimated delivery time, e.g., 3-5 business days], though this may vary depending on your location and any external factors like holidays. Once your order is shipped, you will receive an email containing tracking information, allowing you to monitor its progress. Please ensure that your delivery address is accurate to avoid any issues. If you have any questions or face any delivery-related concerns, our customer support team is available to assist you. You can reach us at [provide contact details]. If you have specific delivery instructions or requirements, please communicate them during the checkout process or by contacting our customer support team.',
    },
    // Add more FAQ items here
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (


    <>
      <div className="mt-28 ">
        <div className="container mx-auto py-8 p-6">
          
          <h5 className=' underline text-center font-semibold  justify-center  text-2xl uppercase' style={{ fontFamily: "Tenor Sans, sans-serif", textDecoration: "underline #FBC44B " }}>FAQ</h5>
          <h5 className="text-4xl text-center font-semibold mb-4">
            Answers From Representatives
          </h5>
          <p className="text-center text-lg ">
            We decided to answer your queries
          </p>
          <div className="flex justify-center mt-10  items-center">
            <div className="space-y-4 w-full max-w-3xl">
              {faqData.map((faq, index) => (
                <div key={index}>
                  <button
                    onClick={() => toggleAccordion(index)}
                    className="flex justify-between items-center w-full p-2 bg-[#FBC44B] rounded-lg hover:bg-[#FBC44B]">
                    <span className="text-lg  font-medium whitespace-break-spaces">
                      {faq.question}
                    </span>
                    <span className="text-4xl font-semibold" style={{ color: "#952F8A" }}>
                      {activeIndex === index ? '-' : '+'}
                    </span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-4 bg-[#952F8A] text-white rounded-lg shadow">
                      <p>{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}
export default Faq;

