import {  defaultApi } from "./api";
import { IMAGE_BASE_URL } from "./constant";

export async function getBanners({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/getBanners`);
  return res.data.data.map(i => {
    i.banner_image = `${IMAGE_BASE_URL}/${i.banner_image}`
    return i
  })
}

export async function getHomeManageData({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/getHomeManage`);
  return res.data.data;
}

export async function getCategorySectionData({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/categorywise_sections`);
  return res.data.data;
}

export async function getContentSectionData({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/contentwise_sections`);
  return res.data.data.map(i => {
    i.image_url = `${IMAGE_BASE_URL}/${i.image_url}`
    return i
  })
}

export async function getBannersSectionData({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/bannerwise_sections`);
  return res.data.data.map(i => {
    i.image_url = `${IMAGE_BASE_URL}/${i.image_url}`
    return i
  })
}

export async function getHotOffersData({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/hot_offers`);
  return res.data.data.map(i => {
    i.imagesdata?.map(img =>
      img.image_url = `${IMAGE_BASE_URL}/uploads/${img.image_url}`
    )
    return i
  })
}

export async function getOffersData({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/getOffers`);
  return res.data.data.map(i => {
    i.offer_image = `${IMAGE_BASE_URL}/${i.offer_image}`
    return i
  })

}

export async function getPerks({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/getPerks`);
  return res.data.data.map(i => {
    i.perk_image = `${IMAGE_BASE_URL}/${i.perk_image}`
    return i
  })
}

export async function getTagwiseData({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/admin/tagwise_sections`);
  return res.data.data;
}


