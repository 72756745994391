import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function EnterOTP() {
  const [otp, setOtp] = useState('');
  const [userId, setUserId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userIdParam = searchParams.get('user_id');

    if (userIdParam) {
      setUserId(userIdParam);
    } else {
      // Handle case where user_id is not provided in the URL
      // For example, redirect to an error page or back to the login page
      navigate('/login');
    }
  }, [location.search, navigate]);

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/otpVerification`, {
        otp: otp,
        user_id: userId, // Include user_id in the request
      });

      if (response && response.data) {
        const { result, msg, data } = response.data;

        if (result === 1) {
          navigate(`/resetpassword?user_id=${data.user_id}`);
        } else {
          alert(msg);
        }
      } else {
        alert('Invalid response received from the server.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP. Please try again.');
    }
  };

  return (
    <div className="min-h-screen mt-12 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg border shadow-md w-full max-w-md">
        <h5 className="font-extrabold text-center mb-6 text-2xl uppercase" style={{ fontFamily: "Tenor Sans, sans-serif", textDecoration: "underline #FBC44B " }}>
          Enter OTP
        </h5>
        <form onSubmit={handleVerifyOTP} className="space-y-4">
          <div>
            <label htmlFor="otp" className="block text-lg font-medium text-gray-700 mb-2">
              OTP:
            </label>
            <input
              type="text"
              id="otp"
              placeholder="Enter OTP"
              className="w-full text-black px-4 py-3 rounded-lg border border-gray-300 focus:ring focus:ring-indigo-500"
              value={otp}
              onChange={handleOtpChange}
              required
            />
          </div>
          <button
            type="submit"
            className="bg-[#952F8A] w-full text-white rounded-lg py-2 text-lg font-medium"
          >
            Verify OTP
          </button>
        </form>
        <div className="text-center mt-4">
          <Link to="/login" className="text-indigo-600">
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EnterOTP;
