import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

// ... (other imports)
function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError('Email is required!');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/forgotPassword`, {
        email: email,
      });

      if (response && response.data) {
        const { result, msg, data } = response.data;

        if (result === 1) {
          setResetSuccess(true);
          navigate(`/enterotp?user_id=${data.user_id}`);
          toast.success(msg);
          // console.log(data.user_id);
        } else {
          setError(msg);
          setResetSuccess(false);
          toast.error(msg);
        }
      } else {
        setError('Invalid response received from the server.');
        setResetSuccess(false);
        toast.error('Invalid response received from the server.');
      }
    } catch (error) {
      setError('Password reset failed. Please try again.');
      setResetSuccess(false);
      console.error(error);
      toast.error('Password reset failed. Please try again.');
    }
  };

  return (
    <>
      <div className="min-h-screen mt-12 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg border shadow-md w-full max-w-md">
          <h5 className="font-extrabold text-center mb-6 text-2xl uppercase" style={{ fontFamily: "Tenor Sans, sans-serif", textDecoration: "underline #FBC44B " }}>
            Forgot Password
          </h5>
          <form onSubmit={handleResetPassword} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-lg font-medium text-gray-700 mb-2">
                Email:
              </label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                className={`w-full text-black px-4 py-3 rounded-lg border border-gray-300 focus:ring focus:ring-indigo-500 ${emailError && 'border-red-500'}`}
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <p className="text-red-500">{emailError}</p>}
            </div>
            {error && <p className="text-red-500">{error}</p>}
            {resetSuccess && <p className="text-green-500"> Successful!</p>}            <button
            onClick={handleResetPassword}
            type="submit"
            className={`bg-[#952F8A] w-full text-white rounded-lg py-2 text-lg font-medium mt-5 ${!email && 'cursor-not-allowed opacity-50'}`}
          >
            Reset Password
          </button>
          </form>
          <div className="text-center mt-4">
            <Link to="/login" className="text-indigo-600">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default ForgotPassword;

