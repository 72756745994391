import React , {useEffect} from "react";
import moment from "moment";

const OrderDetailsModal = ({ order, onClose }) => {
  useEffect(() => {
    window.scrollTo(0,0);
     },[])
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 max-w-md rounded">
        <h2 className="text-lg font-semibold mb-4 text-center">Order ID: {order.id}</h2>
        <p>Total Amount: {order.total_amount}</p>
        <p>Order Status: {order.status}</p>
        <p>Order Date: {moment(order.created_at).format("MMMM Do, YYYY, hh:mm A")}</p>

        <div className="mt-4">
          <h3 className="text-md font-semibold mb-2">Order Items:</h3>
          <ul>
            {order.items.map((item) => (
              <li key={item.id}>
                Product ID: {item.product_id}, Quantity: {item.quantity}, Price: {item.price}
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-4">
          <h3 className="text-md font-semibold mb-2">User Details:</h3>
          <p>User ID: {order.users.user_id}</p>
          <p>Name: {`${order.users.first_name} ${order.users.last_name}`}</p>
          <p>Email: {order.users.email}</p>
          <p>Phone Number: {order.users.phone_no}</p>
        </div>

        <button className="mt-4 bg-blue-500 text-white py-2 px-5 rounded flex mx-auto" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default OrderDetailsModal;
