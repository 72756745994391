import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  getCategory,
  getParentCategories,
  getProducts,
} from "../../services/productService";
import Header from "../Header/Header";
import './Style.css';

function Sweets({ pageTitle }) {
  const location = useLocation();
  useEffect(() => {
    let pageTitle;
    if (location.pathname.includes("/sweets")) {
      pageTitle = "Sweets";
    } else {
      pageTitle = "Kachoris";
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0,0);
     },[])
     
  const [sortBy, setSortBy] = useState("name");
  const [filterByPrice, setFilterByPrice] = useState([]);
  const [filterByPackSize, setFilterByPackSize] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  // const { addToCart } = useCart();

  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const [categoryData, setCategoryData] = useState({});

  let { data: productList } = useQuery({
    queryKey: ["product", page],
    queryFn: getProducts,
  });

  useEffect(() => {
    setFilteredProduct(productList?.data);
  }, [productList]);

  useEffect(() => {
    filterProduct();
  }, [categoryData]);

  let filterProduct = () => {
    if (categoryData.length > 0) {
      let tempList = productList?.data;
      tempList = tempList?.filter((el) =>
        categoryData.includes(el.category_id)
      );
      setFilteredProduct(tempList);
    }
  };

  const category_id = searchParams.get("category_id");

  // console.log("category_id", category_id);

  const mutateGetParentCategory = useMutation({
    mutationFn: getCategory,
    mutationKey: ["parent_category", category_id],
    onSuccess: (data) => {
      let tempId = data.map((el) => el.category_id);
      // console.log("parent_category", tempId);
      setCategoryData(tempId);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    if (category_id) {
      mutateGetParentCategory.mutate(category_id);
    }
  }, [category_id]);

  useEffect(() => {
    if (category_id) {
      mutateGetParentCategory.mutate(category_id);
    }
  }, [category_id]);

  

  const handleSort = (type) => {
    setSortBy(type);
  };

  const handleFilterPrice = (price) => {
    if (filterByPrice.includes(price)) {
      setFilterByPrice(filterByPrice.filter((p) => p !== price));
    } else {
      setFilterByPrice([...filterByPrice, price]);
    }
  };

  const handleFilterPackSize = (size) => {
    if (filterByPackSize.includes(size)) {
      setFilterByPackSize(filterByPackSize.filter((s) => s !== size));
    } else {
      setFilterByPackSize([...filterByPackSize, size]);
    }
  };

  return (
    <>
      <h5
        className=" mt-32  text-center justify-center underline text-2xl uppercase"
        style={{
          fontFamily: "Tenor Sans, sans-serif",
          textDecoration: "underline #FBC44B ",
        }}
      >
        {pageTitle}
      </h5>
      <div className="mt-10 mx-4 md:mx-8 lg:mx-8 xl:mx-20 gap-5 flex flex-col-reverse md:flex-row justify-between">
        <div className="w-full grid grid-cols-3 max-sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4">
          {filteredProduct?.map((product, index) => (
            <Link to={`/product/${product.slug}`} key={index}>
              {/* Cards */}
              <div className="flex flex-col bg-white p-2 shadow-md rounded-lg border">
                <div className="cursor-pointer">
                  <img
                    src={product.thumbnail_image}
                    alt={product.product_name}
                    // className="w-full h-48 max-sm:w-full max-sm:h-45 sm:items-center border border-gray-200 object-cover rounded-lg mb-2"
                    className=" sm:items-center border border-gray-200 object-cover rounded-lg mb-2 flex  mx-auto imagesection"
                    // style={{width:"280px", height:"220px", '@media (max-width: 740px)': {
                    //     width: '100%',
                    //     height: '150px', 
                    //   },}}
                  />
                </div>
                <div className="text-center">
                  <h5 className="text-md">
                    <span className="font-semibold capitalize line-clamp-1">
                      {product.product_name}
                    </span>
                    <p style={{ color: "rgb(162, 7, 141)" }}>
                      Price - {product.product_price}
                    </p>
                    {/* <span>({[product?.product_quantity]})</span> */}
                  </h5>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
export default Sweets;
