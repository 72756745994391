import React,{useEffect} from 'react'
import Footer from '../../Footer/Footer'
import { Helmet } from "react-helmet";
function About() {
    useEffect(() => {
        window.scrollTo(0,0);
         },[])
    return (
        <>
          <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-VY1YL2WN1S"></script>
  <script>
    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-VY1YL2WN1S');
    `}
  </script>
  <script type="text/javascript">
                    {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "mdcd40ib4r");
                    `}
                </script>
        <meta charSet="utf-8" />
        <title>Kuku Namkeen | About Us</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <script>
                    {`
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '398356959855805');
                        fbq('track', 'PageView');
                    `}
                </script>
                <noscript>
                    {`
                        <img height="1" width="1" style="display:none"
                             src="https://www.facebook.com/tr?id=398356959855805&ev=PageView&noscript=1" />
                    `}
                </noscript>
      </Helmet>
            <div className='mt-28'>
                <h5 className='text-center text-2xl uppercase font-bold' style={{ fontFamily: "Tenor Sans, sans-serif", textDecoration: "underline #FBC44B " }}>About Us</h5>
                <div className='lg:px-24 max-md:px-8 md:px-10 max-sm:px-6 py-10'>
                    <h3 className='text-xl font-semibold max-sm:text-center  whitespace-nowrap'>OUR STORY SO FAR:</h3>
                    <p className='mt-6 text-md font-normal text-ellipsis'style={{fontFamily:"Montserrat,sans-serif", textAlign:"justify"}}>Kuku Namkeen is a renowned snack manufacturer located in Jamnagar, Gujarat, India. Our journey began with a small shed and a passion for crafting authentic, flavorful snacks. Over the years, we've grown, innovated, and embraced sustainability while staying true to our commitment to quality and tradition. Today, Kuku Namkeen stands as a symbol of Gujarat's culinary heritage, offering a diverse range of snacks that capture the essence of our culture. We're not just a snack company; we're a tradition, a legacy, and a taste of Gujarat.
                        Today, Kuku Namkeen stands as a testament to the power of tradition, innovation, and unwavering dedication. Shed no. A/4 on Hirjimistri Road in Jamnagar is not just a place of business; it's a symbol of the journey of a family, a community, and a culture that has come together to create something extraordinary.
                        As Kuku Namkeen looks ahead, the mission remains the same—to continue crafting flavors that create memories. The small shed in Jamnagar has become an institution, and the legacy of Kuku Patel lives on through every bite of namkeen that leaves the factory.
                        With a vision to expand globally and share the flavors of Gujarat with the world, Kuku Namkeen is poised for an exciting future. The journey from that humble shed to international recognition has been nothing short of extraordinary, and it's a story that continues to inspire and delight.
                        In a world that is constantly changing, Kuku Namkeen remains a steadfast guardian of tradition, flavor, and quality. From its origins in a small shed in Jamnagar to becoming a household name across India, Kuku Namkeen's story is a testament to the enduring power of passion and dedication.

                        The next time you savor a piece of Kuku Namkeen, remember that you are not just enjoying a snack; you are experiencing a taste of Gujarat's rich heritage. Shed no. A/4 on Hirjimistri Road will forever be the place where it all began—a place where flavors were born, traditions were upheld, and memories were created, one delicious bite at a time.
                        As the age old saying goes,<span className='font-bold text-md'> “The way to one’s heart courses through their stomach”. </span> We want to leave no stone unturned to win your hearts.

                    </p>
                </div>
            </div>

           
        </>
    )
}

export default About
