import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";

import { useUser } from "../../utils/UserContext";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { FaRegAddressCard } from "react-icons/fa6";
import { MdOutlinePayment } from "react-icons/md";
import axios from "axios";

function OrderPlaced() {
  const [orderData, setOrderData] = useState({})
  const { userDetails } = useUser();
  const { id } = useParams();
  useEffect(() => {
    fetchOrder()
    window.scrollTo(0, 0);
  }, [])

  const fetchOrder = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/order/${id}`, {
      headers: {
        "token": localStorage.getItem("token")
      }
    }).then((res) => {
      setOrderData(res.data.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-4 rounded-lg shadow-md max-w-md mt-10 mb-5 ">
        <div className="justiy-center ">
          <p style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{
                color: "rgb(0,179,149)",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <HiOutlineShoppingCart size={25} /> Cart------{" "}
              <FaRegAddressCard
                size={25}
                style={{ marginLeft: "3px", marginRight: "4px" }}
              />{" "}
              Address------{" "}
              <MdOutlinePayment
                size={25}
                style={{ marginLeft: "3px", marginRight: "1px" }}
              />
              Payment
            </span>
          </p>
        </div>
      </div>

      <div className="bg-white p-2 mx-5 rounded-lg shadow-md max-w-md">
        <h2 className="text-3xl font-semibold mb-6 text-center text-indigo-600">
          Order Placed Successfully
        </h2>

        <div className="mb-4">
          <p><b>Order ID:</b> {id}</p>
          <p><b>Amount:</b> 	&#8377;{orderData.total_amount}</p>
          <p className="text-lg font-medium text-center text-gray-700">
            Thank you for your order
          </p>
          <p className="text-gray-600 text-center">
            Your order has been placed successfully.
          </p>
        </div>

        {/*     <div className="mb-6">
          <p className="text-lg font-medium">Order Details:</p>
          <p>{userDetails.name} </p>
          <p>{userDetails.address}, {userDetails.city}, {userDetails.state}, {userDetails.zipcode}</p>
          <p>Phone: {userDetails.phone}</p>
  </div>*/}

        <div className="mb-8 flex justify-center">
          <Link to="/orders">
            <button
              className="bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring focus:border-indigo-300"
            // onClick={() => {
            //   // Add logic to download the invoice
            //   alert('Download Invoice');
            // }}
            >
              View Orders{" "}
            </button>
          </Link>
        </div>

        <p className="text-gray-600 text-center">
          You will also receive an email confirmation shortly.
        </p>
      </div>
    </div>
  );
}

export default OrderPlaced;
