import React, { useState } from "react";
import image from "./register.png";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Scale } from "lucide-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';

function Register() {
    const navigate = useNavigate()
  const [register, setRegister] = useState(true);
  const [otp, setOtp] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const handleOTP = () => {
    setRegister(false);
    setOtp(true);
  };

  const backRegister = () => {
    setOtp(false);
    setRegister(true);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!emailOrPhone) {
      toast.info("Please enter Email or Mobile Number");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/userLoginByOtp`,
        {
          email_or_phone: emailOrPhone,
        }
      );
      // console.log("OTP Sent Successfully", response);
      toast.success("OTP Sent Successfully ");
      //   setEmailOrPhone('');
      handleOTP();
    } catch (error) {
      toast.error("Failed Sending OTP");
      console.log("Failed Sending OTP", error);
    } finally {
      setLoading(false);
    }
  };



  const handleSubmit = async(e) => {
    e.preventDefault();
    if (!verificationCode) {
      toast.info("Please enter OTP");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/userLoginVerification`,
        {
          email_or_phone: emailOrPhone,
          otp: verificationCode
        }
      );
      // console.log("Registration Successfully", response);
      toast.success("Registration Successfully ");
        // setEmailOrPhone('');
        // setVerificationCode('');
        const token  = response.data.data.authenticate.user_token;
        localStorage.setItem('token', token);
        localStorage.setItem('user_id', response.data.data.authenticate.user_id);
        navigate("/");
    } catch (error) {
      toast.error("Registration Failed ");
      console.log("Registration Failed ", error);
    } finally {
      setLoading(false);
    }
  };
//   const handleResend = () => {
//     handleRegister();
//   }

  return (
    <div className="min-h-screen flex justify-center items-center">
      <ToastContainer />
      {register && (
        <div className="w-full lg:w-3/4" style={{overflow:"hidden"}}>
          <div className="flex justify-center px-6 py-12 ">
            <div
              className="w-full lg:w-3/4 flex rounded-l-lg border border-black-700 bg-gray-50 shadow-2xl"
              style={{ borderRadius: "10px", overflow: "hidden" }}
            >
              <div
                className="w-full h-auto   hidden md:block lg:w-6/12 bg-cover rounded-l-lg"
                // style={{ backgroundImage: "url('https://source.unsplash.com/Mv9hjnEUHR4/600x800')" }}>
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div
                className="w-full lg:w-6/12   px-5 py-8  lg:rounded-l-lg mx-auto"
                style={{ maxWidth: "500px" }}
              >
                <h3 className="py-4 text-2xl text-center text-gray-800 ">
                  Login / Register
                </h3>
                <form className="px-8 pt-6 pb-8 mb-4 bg-gray-200 rounded">
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-md font-bold text-gray-700 "
                      htmlFor="email"
                    >
                      Email or Mobile
                    </label>
                    <input
                      className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="email"
                      type="text"
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)}
                      placeholder="Enter Email or Mobile Number"
                    />
                  </div>

                  <div className="text-center">
                    <a
                      className="inline-block align-baseline h text-black my-4"
                      style={{ fontSize: "12px" }}
                      href="#"
                    >
                      By Signing, I agree to Terms & Conditions
                    </a>
                  </div>
                  <hr className="mb-8 border-t border-gray-400" />

                  <div className="mt-8 mb-2 text-center">
                    {loading ? (
                      <button className="w-full px-4 py-2 font-bold text-white bg-[#952F8A] rounded-full  focus:outline-none focus:shadow-outline">
                        Loading...
                      </button>
                    ) : (
                      <button
                        className="w-full px-4 py-2 font-bold text-white bg-[#952F8A] rounded-full  focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleRegister}
                      >
                        Send OTP
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {otp && (
        <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-12 px-10"style={{overflow:"hidden"}}>
          <div
            className="relative bg-white px-6 pt-10 pb-9 shadow-2xl mx-auto bg-gray-50 w-full max-w-lg rounded-2xl border border-black-700"
            // style={{ minWidth: "350px" }}
          >
            <div className="absolute top-4 left-4">
              <RiArrowLeftSLine
                size={24}
                style={{
                  color: "black",
                  border: "1px solid #a5a5a5",
                  borderRadius: "100px",
                  cursor: "pointer",
                }}
                onClick={backRegister}
              />
            </div>
            <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
              <div className="flex flex-col items-center justify-center text-center space-y-2">
                <div className="font-semibold text-3xl">
                  <p>Email Verification</p>
                </div>
                <div className="flex flex-row text-sm font-medium text-gray-400">
                  <p>We have sent a code to {emailOrPhone}</p>
                </div>
              </div>

              <div>
                <form action="" method="post">
                  <div className="flex flex-col space-y-16">
                    <div className="flex flex-row items-center justify-between mx-auto w-full max-w-full">
                      <div className="w-full h-16">
                      <input
  className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:ring-1 ring-blue-700"
  type="tel" 
  maxLength={4}
  onChange={(e) => {
    const numericInput = e.target.value.replace(/\D/g, '');
    setVerificationCode(numericInput.slice(0, 4));
  }}
/>

                      </div>
                    </div>

                    <div className="flex flex-col space-y-5">
                      <div>
                      {loading ? (
                      <button
                      className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-[#952F8A] border-none text-white text-sm shadow-sm"
                    >
                      Loading...
                    </button>
                    ) : (
                        <button
                        className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-[#952F8A] border-none text-white text-sm shadow-sm"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Verify Account
                      </button>
                    )}
                        
                      </div>

                      {/* <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                        <p>Didn't receive code?</p>
                        <div
                          className="flex flex-row items-center text-blue-600"
                           onClick={handleResend}
                        >
                          Resend
                        </div>
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
