import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import OrderDetailsModal from "./OrderDetailsModal";
import { getLocalUserId } from "../../utils/getLocalUserId";

const PlacedOrdersPage = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const userId = getLocalUserId();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/order/user/${userId}`);
        setOrderDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching order details", error);
      }
    };

    fetchOrderDetails();
  }, [userId]);

  useEffect(() => {
    window.scrollTo(0,0);
     },[])
     
  const openOrderDetailsModal = (order) => {
    setSelectedOrder(order);
  };

  const closeOrderDetailsModal = () => {
    setSelectedOrder(null);
  };

  return (
    <div className="p-5" style={{ marginTop: "70px" }}>
      {orderDetails.length === 0 ? (
        <p>No orders found.</p>
      ) : (
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Order ID</th>
              <th className="px-4 py-2">Total Amount</th>
              <th className="px-4 py-2">Order Status</th>
              <th className="px-4 py-2">Order Date</th>
              <th className="px-4 py-2">Details</th>
            </tr>
          </thead>
          <tbody>
            {orderDetails.map((order) => (
              <tr key={order.id}  >
                <td className="border px-4 py-2">{order.id}</td>
                <td className="border px-4 py-2">{order.total_amount}</td>
                <td className="border px-4 py-2">{order.status}</td>
                <td className="border px-4 py-2">{moment(order.created_at).format("MMMM Do, YYYY, hh:mm A")}</td>
                <td className="border px-4 py-2 text-center" style={{ color:"blue"}} onClick={() => openOrderDetailsModal(order)}><p style={{ cursor: "pointer",}}>Details</p></td>

              </tr>
            ))}
          </tbody>
        </table>
      )}

      {selectedOrder && (
        <OrderDetailsModal order={selectedOrder} onClose={closeOrderDetailsModal} />
      )}
    </div>
  );
};

export default PlacedOrdersPage;
