import React, { useState, useEffect } from "react";
import "./seller.css";
import axios from "axios";

function BestSeller() {
  const [seller, setSeller] = useState([]);

  const handleSeller = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/product/getProducts?is_featured=yes&status=Active`
      );
      // console.log("Best Seller Fetched");

      setSeller(response.data.data.data);
    } catch (error) {
      console.error("Error fetching best seller", error);
    }
  };

  useEffect(() => {
    handleSeller();
  }, []);

  return (
    <div className="my-5">
      <div className="flex justify-center w-full">
        <h2 className="text-3xl font-bold" style={{ color: "rgb(162, 7, 141)" }}>
          Best Seller
        </h2>
      </div>
      <div className="container mx-auto mt-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          {seller.map((data) => (
            <div key={data.id} className="max-w-sm mx-auto ">
              <div className="flex h-full  p-2 flex-col " style={{width:"320px"}}>
                <a href={`/product/${data?.slug}`}>
                  <img
                    src={data?.thumbnail_image}
                    alt="Product_image"
                    className="mx-auto rounded-xl"
                    style={{ width: "200px", height: "200px" }}
                  />
                  <p className="text-l font-normal pt-1 text-center overflow-hidden line-clamp-1  px-5" >
                    {data?.product_name}
                  </p>
                  <p className="text-l font-normal pt-1 text-center" style={{ color: "rgb(162, 7, 141)", fontWeight:"400" }}>
                    Price - Rs {data?.product_price}
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BestSeller;
