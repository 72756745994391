export default function PaymentError() {
    return (
      <section className="max-w-sm px-4 py-20 w-full mx-auto">
        <div className="text-center shadow-lg border border-gray-100 py-10 px-4">
          <div className="mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-20 text-red-600 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <h1 className="text-2xl font-semibold mb-2 text-red-600">Error</h1>
          <p className="text-red-600">
            We have not received your purchase request;
            we'll be in touch shortly!
          </p>
        </div>
      </section>
    );
  }
  