import { getLocalUserId } from "../utils/getLocalUserId";
import { defaultApi } from "./api";
import { GET_CATEGORY_URL, IMAGE_BASE_URL } from "./constant";
import { PRODUCT_DETAILS_URL } from "./constant";
import { PRODUCT_GET_STATIC_PAGE_URL } from "./constant"




export async function getProducts({ queryKey }) {
  const { 0: filterParameter, 1: filterId } = queryKey;
  
  const filterStr = filterParameter ? `?${filterParameter}=${filterId}` : "";
  // const pageStr = page ? `?page=${page}` : "";
  const res = await defaultApi.get(`/api/product/getProducts${filterStr}&status=Active`);
  return res.data.data;
}

export async function getProductsQuery({ queryKey }) {
  const { 1: param } = queryKey;
  const res = await defaultApi.get(
    `/api/product/getProducts?status=Active&slug=${param.slug}&status=Active`
  );
  return res.data;
}

export async function getProductImages(id) {
  const formData = new FormData();
  formData.append("product_id", id);
  const res = await defaultApi.post(`/api/product/getProductImages`, formData);
  return res.data;
}

export async function addCartMutation(data) {
  const formData = new FormData();
  formData.append("product_id", data.data.product_id);
  formData.append("user_id", data.user_id);
  formData.append("price", data.data.product_sell_price);
  formData.append("quantity", data.quantity);
  const res = await defaultApi.post(`/api/cart/add`, formData);
  return res;
}

export async function getCarts(args) {
  const { queryKey: { 1: obj } } = args;
  const res = await defaultApi.get(`/api/cart/getCart?user_id=${obj.user_id}`);
  const a={};
  for (const [key, value] of Object.entries(res.data.data)) {
    const id = value.product_id;
    const product = await defaultApi.get(`/api/product/getProducts?product_id=${id}&status=Active`)

    value.thumbnail_image = product.data.data.thumbnail_image;
    value.product_name = product.data.data.product_name;
    a[key]=value;
    
  }
  res.data.data=a;

  return res;
}

export async function clearCarts(data) {
  try {
    const user_id = data?.user_id ?? getLocalUserId();
    const res = await defaultApi.get(`/api/cart/clear?user_id=${user_id}`);
    return res.data;
  } catch (e) {
    return e;
  }
}

export async function updateCarts(data) {
  return  defaultApi.post('/api/cart/update', data);
  // try { 
  //   const formData = new FormData();
  //   formData.append('cart_id', cart_id);
  //   formData.append('quantity', quantity);
  //   const res = await defaultApi.post('/api/cart/update', formData);
  //   console.log(res);
  //   return res.data;
  // } catch (e) {
  //   return e;
  // }
}

export async function removeCarts({ cart_id }) {
  try {
    const formData = new FormData();
    formData.append('cart_id', cart_id);
    const res = await defaultApi.post('/api/cart/remove', formData);
    return res.data;
  } catch (e) {
    return e;
  }
}

export async function createOrder(data) {
  try {
    const token = localStorage.getItem("token");
    const res = await defaultApi.post('/api/order/create', data, {
      headers: {
        token: token
      }
    });
    return res.data;
  } catch (e) {
    return e;
  }
}

export async function createOrderPayment(data) {
  try {
    const res = await defaultApi.post('/api/payment-order', data);
    return res.data;
  } catch (e) {
    return e;
  }
}

export async function getParentCategories(page) {
  const pageStr = page ? `?parent_category_id=${page}` : "";
  const res = await defaultApi.get(`/api/product/getParentCategories${pageStr}`);
  return res.data.data;
}

export async function getCategory(page) {
  const pageStr = page ? `?parent_category_id=${page}` : "";
  const res = await defaultApi.get(`/api/product/getCategories${pageStr}`);
  return res.data.data;
}
export async function getCategories({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get(`/api/product/getCategories`);
  return res.data.data.map(i => {
    i.category_image = `${IMAGE_BASE_URL}/${i.category_image}`
    return i })
} 

export async function getProductByCategoryID(id) {
  const pageStr = id ? `?category_id=${id}` : "";
  const res = await defaultApi.get(`/api/product/getProducts${pageStr}&status=Active`);
  return res.data.data;
}

export async function getStaticPageData({ queryKey }) {
  // later replace this with slug right now page_id is being passed because slug is not configured in backend
  // const pageStr = queryKey[1] ? `?page_id=${ queryKey[1] }` : "";
  const res = await defaultApi.get(`${PRODUCT_GET_STATIC_PAGE_URL}`);
  return res.data.data;
}

export async function getColor(page) {
  // const pageStr = page ? `?parent_category_id=${page}` : "";
  const res = await defaultApi.get(`/api/product/getColors`);

  return res.data.data;
}