import React from 'react'

const IndexHeader = ({indexHeaderName}) => {
  return (
    <div className=' text-center'>
        <h2 className='text-center  text-2xl uppercase' style={{ fontFamily: "Tenor Sans, sans-serif", textDecoration: "underline #FBC44B " }}>{indexHeaderName}</h2>
    </div>
  )
}

export default IndexHeader
