import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useQuery } from '@tanstack/react-query';
import { getBanners } from '../../services/HomeManageService';
import { Link } from 'react-router-dom';
import "./Hero.css";


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Herom() {
   const { data: bannerData } = useQuery({
    queryKey: ["banners"],
    queryFn: getBanners 
  });
 
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = bannerData?.length;



  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <Box sx={{  flexGrow: 1,position:'relative' }} >
      
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {bannerData?.map((item, index) => (
          <div key={item.banner_id}>
            {Math.abs(activeStep - index) <= 2 ? (
            <div className="w-full flex relative flex items-start  justify-center mt-20" >
            <img
              src={`${item.banner_image}`}
              className="w-full object-fit "
              style={{height:"25vh"}} 
              alt="Product"
            />
            <div className="absolute flex flex-col  items-center justify-center px-24 w-full" style={{height:"25vh"}}>
            {/* <h1 className="text-5xl text-white font-bold text-center" dangerouslySetInnerHTML={{
                __html: item.banner_name,
              }}>
              
            </h1>
            <Link to={item.banner_link} className="mt-3">
          <button className='px-12 py-1 primary-btn text-white text-2xl '>
            More...
          </button>
          </Link> */}
        </div>
          </div>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="absolute"
        activeStep={activeStep}
        sx={{
          bottom:0,
          position:'absolute',
          display:'flex',
          justifyContent:'center',
          width:'100%',
          backgroundColor:'transparent'
        }}
      />
      <div className='mb-5'></div>
    </Box>
  )
}

export default Herom