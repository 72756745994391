import { useQuery } from "@tanstack/react-query";
import { getLocalUserId } from "../../utils/getLocalUserId";
import { getCarts } from "../../services/productService";


export const useGetCarts = () => {
  const user_id = getLocalUserId();
  return useQuery({
    queryKey: ["get-cart", { user_id }],
    queryFn: getCarts,
  }); 
};
