import React, { useState, useEffect } from "react";
import "./Checkout.css";
import FormatPrice from "../../FormatPrice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCarts,
  removeCarts,
  updateCarts,
} from "../../services/productService";
import { getLocalUserId } from "../../utils/getLocalUserId";
import { setLocalStorage, getLocalStorage } from "../../utils/localStorage";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../utils/UserContext";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { FaRegAddressCard } from "react-icons/fa6";
import { MdOutlinePayment } from "react-icons/md";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../../utils/getToken";
function Checkout() {
  // const history = useHistory();

  useEffect(() => {
    if (!getToken()) {
      navigate("/sign-up");
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [userAddresses, setUserAddresses] = useState([]);
  const [orderid, setOrderid] = useState(null);
  const [amount, setamount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [access, setAccess] = useState(null);
  const [encrypted, setEncrypted] = useState(null);

  let totalCartPrice = 0;

  const [checkoutInput, setCheckoutInput] = useState(() => {
    // Use local storage data or default values if not available
    return getLocalStorage("checkoutInput", {
      name: "",
      phone: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
    });
  });

  const [error, setError] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const { updateUserDetails, userDetails } = useUser();
  const handleInput = (e) => {
    e.persist();
    setCheckoutInput({ ...checkoutInput, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newError = {};
    let isValid = true;

    Object.entries(checkoutInput).forEach(([key, value]) => {
      if (value.trim() === "") {
        newError[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } is required`;
        isValid = false;
      }
    });

    setError(newError);
    return isValid;
  };

  const submitOrder = (e, payment_mode) => {
    e.preventDefault();

    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    setLocalStorage("checkoutInput", checkoutInput);

    updateUserDetails(checkoutInput);

    // navigate("/orderplaced");

    const data = {
      name: checkoutInput.name,
      phone: checkoutInput.phone,
      email: checkoutInput.email,
      address: checkoutInput.address,
      city: checkoutInput.city,
      state: checkoutInput.state,
      zipcode: checkoutInput.zipcode,
      payment_mode: payment_mode,
      payment_id: "",
    };

    switch (payment_mode) {
      case "cod":
        // Simulating order placement
        // console.log("Placing COD Order:", data);
        break;

      case "razorpay":
        // Simulating Razorpay payment
        // console.log("Placing Razorpay Order:", data);
        break;

      case "payonline":
        // Simulating online payment
        // console.log("Validating Online Payment:", data);
        break;

      default:
        break;
    }
  };

  const queryClient = useQueryClient();
  const user_id = getLocalUserId();

  const { data: cartData } = useQuery({
    queryKey: ["get-cart", { user_id }],
    queryFn: getCarts,
  });

  const mutateUpdateCart = useMutation({
    mutationKey: ["update-cart"],
    mutationFn: updateCarts,
    onSuccess: () => {
      const stored = JSON.parse(localStorage.getItem("carts") || "{}");
      setCartItem(stored);
      queryClient.invalidateQueries("get-cart");
    },
  });

  const mutateRemoveCart = useMutation({
    mutationKey: ["remove-cart"],
    mutationFn: removeCarts,
    onSuccess: () => {
      queryClient.invalidateQueries("get-cart");
    },
  });

  const [cartItems, setCartItem] = useState({});
  const carts = Object.values(cartData?.data?.data || {});

  const updateItem = (item, quantity) => {
    const localCart = JSON.parse(localStorage.getItem("carts") || "{}");
    if (quantity > 0) {
      const newCart = {
        ...localCart,
        [item.product_id]: {
          ...localCart[item.product_id],
          quantity,
        },
      };
      localStorage.setItem("carts", JSON.stringify(newCart));
      mutateUpdateCart.mutate({ cart_id: item.id, quantity });
    } else {
      mutateRemoveCart.mutate({ cart_id: item.id });
    }
  };

  useEffect(() => {
    // Clear errors when navigating away
    return () => {
      setError({
        name: "",
        phone: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
      });
    };
  }, []);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");

  const handleAddressButtonClick = () => {
    setShowAddressModal(true);
    handleAddress();
  };
  const [addressDetail, setAddressDetail] = useState({
    first_name: "",
    email: "",
    phone_no: "",
    city: "",
    state: "",
    pincode: "",
  });
  const handleAddressCheckboxChange = (address, fullDetail) => {
    setSelectedAddress(address);
    handleShipment(address);
    setAddressDetail({
      first_name: fullDetail.name,
      email: fullDetail.email,
      phone_no: fullDetail.phone_no,
      city: fullDetail.city,
      state: fullDetail.state,
      pincode: fullDetail.pincode,
    });
  };
  const [show, setShow] = useState(false);
  const [shipment, setShipment] = useState("");
  const [companyId, setCompanyId] = useState("");
  const handleShipment = async (address) => {
    setShipment("");
    setShow(false);
    try {
      const addressString = address;

      const pincodeRegex = /\b\d{6}\b/;

      const pincodeMatch = addressString.match(pincodeRegex);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/courierPrices`,
        {
          pickup_postcode: 110026,
          delivery_postcode: pincodeMatch ? pincodeMatch[0] : "",
          cod: 0,
          weight: cartData?.data?.totalweight,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setShipment(response.data.data.rate);
      setCompanyId(response.data.data.courier_company_id);
      setShow(true);
    } catch (error) {
      console.error("Failed calculating shipment charge", error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.msg || "Failed calculating shipment charge";
        toast.error(errorMessage);
      }
    }
  };

  const handleAddressModalClose = () => {
    setShowAddressModal(false);
    // console.log(selectedAddress);
  };

  const handleAddress = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/${user_id}/addresses`
      );

      const addresses = response.data.data;
      // let address = `${userDetails.name}, ${userDetails.phone}, ${userDetails.address}, ${userDetails.city}, ${userDetails.state}, ${userDetails.zipcode}`;
      // console.log("address1", address);
      setUserAddresses(addresses);
      setShowAddressModal(true);
    } catch (error) {
      console.error("Error fetching addresses", error);
    }
  };
  const handleAddAddress = async () => {
    if (
      !checkoutInput.name ||
      !checkoutInput.phone ||
      !checkoutInput.email ||
      !checkoutInput.address ||
      !checkoutInput.city ||
      !checkoutInput.state ||
      !checkoutInput.zipcode
    ) {
      toast.info("All fields are required");
      return;
    }
    try {
      let fulladdress = `${checkoutInput.name}, ${checkoutInput.phone}, ${checkoutInput.address}, ${checkoutInput.city}, ${checkoutInput.state}, ${checkoutInput.zipcode}`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user-addresses`,
        {
          user_id: user_id,
          address: fulladdress,
          name: checkoutInput.name,
          email: checkoutInput.email,
          phone_no: checkoutInput.phone,
          city: checkoutInput.city,
          state: checkoutInput.state,
          pincode: checkoutInput.zipcode,
          type: "home",
        }
      );
      toast.success("Address Added Successfully");

      // console.log("Address added successfully", response.data);
    } catch (error) {
      toast.error("Failed Adding Address");

      console.error("Error adding address", error);
    }
  };

  const handleDeleteAddress = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/user-addresses/${id}`
      );
      handleAddress();
    } catch (error) {
      console.error("Error deleting address", error);
    }
  };
  const handlePlaceOrder = async () => {
    const carts = Object.values(cartData?.data?.data || {});
    const addressParts = selectedAddress?.split(",").map((part) => part.trim());
    const pincode = addressParts[addressParts.length - 1];
    const state = addressParts[addressParts.length - 2];
    const city = addressParts[addressParts.length - 3];

    const shipmentCharge = totalPrice <= 400 ? 49 : 0;
    // const shipmentCharge = totalPrice <= 400 ? 49 : shipment;


    const orderData = {
      user_id: user_id,
      shipment_charge: shipmentCharge,
      address: selectedAddress,
      courier_company_id: companyId ? companyId : "",
      city: city,
      state: state,
      pincode: pincode,
      first_name: addressDetail?.first_name,
      email: addressDetail?.email,
      phone_no: addressDetail?.phone_no,
      items: carts.map((item) => ({
        product_id: item.product_id,
        quantity: item.quantity,
        price: item.price,
      })),
    };
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/order/create`,
        orderData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrderid(response.data.data.id);
      setamount(response.data.data.total_amount);
      // console.log("Order Placed Successfully");
      setAddressDetail({
        first_name: "",
        email: "",
        phone_no: "",
        city: "",
        state: "",
        pincode: "",
      });
      handlePayment(
        response.data.data.id,
        response.data.data.total_amount + response.data.data.shipment_charge
      );
      localStorage.removeItem("carts");
    } catch (error) {
      console.error("Error placing order", error);
    } finally {
      setLoading(false);
    }
  };

  // const paymentOrder = {
  //   orderId:orderid,
  //   amount:amount,
  //   user:"",
  //   userReferenceId:"",
  //   userDetail:"",
  //   customer_id:user_id
  // };

  const handlePayment = async (order, amt) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment-order`,
        {
          orderId: order,
          amount: amt,
          user: "",
          userReferenceId: "",
          userDetail: "",
          customer_id: user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Order Created", response.data);
      // setAccess(response.data.data.accessCode);
      // setEncrypted(response.data.data.encryptedData);
      navigate(
        `/payment?accessCode=${response.data.data.accessCode}&encryptedData=${response.data.data.encryptedData}`
      );
    } catch (error) {
      console.log("Order failed", error);
    }
  };

  const totalPrice = carts.reduce(
    (acc, cart) => acc + cart.price * cart.quantity,
    0
  );
  // const shipping = shipment;
  // const qualityCheck = 0;
  const shipping = totalPrice <= 400 ? 49 : 0;

  const finalAmount = totalPrice + shipping;
  const showDeliveryMessage = totalPrice <= 400;
  //
  return (
    <div>
      <div className="md:flex p-3 pt-7 lg:p-10 md:p-10 mt-10">
        <div className="md:w-1/2 lg:w-2/3 md:pr-4 mb-4 mt-5">
          <div className="bg-white rounded shadow p-4 pt-2">
            <div className="flex justify-between items-center mb-4">
              <h4 className=" text-2xl font-semibold mb-4">Add new Address </h4>

              {/*   <button
              type="button"
              className="placeorder ml-auto"
              onClick={handleAddressButtonClick}
              >
              View Previous Address
              </button>*/}
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleInput}
                  value={checkoutInput.name}
                  className="mt-1 p-2 w-full border rounded-md"
                />
                <small
                  style={{ color: "red" }}
                  className={`text-danger ${error.name && "visible"}`}
                >
                  {error.name}
                </small>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  onChange={handleInput}
                  value={checkoutInput.phone}
                  className="mt-1 p-2 w-full border rounded-md"
                />
                <small style={{ color: "red" }} className="text-danger">
                  {error.phone}
                </small>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleInput}
                  value={checkoutInput.email}
                  className="mt-1 p-2 w-full border rounded-md"
                />
                <small style={{ color: "red" }} className="text-danger">
                  {error.email}
                </small>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="zipcode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Zip Code
                </label>
                <input
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  onChange={handleInput}
                  value={checkoutInput.zipcode}
                  className="mt-1 p-2 w-full border rounded-md"
                />
                <small style={{ color: "red" }} className="text-danger">
                  {error.zipcode}
                </small>
              </div>
            </div>
            <div className="col-span-2 mb-4">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700"
              >
                Full Address
              </label>
              <textarea
                rows="3"
                id="address"
                name="address"
                onChange={handleInput}
                value={checkoutInput.address}
                className="mt-1 p-2 w-full border rounded-md"
              ></textarea>
              <small style={{ color: "red" }} className="text-danger">
                {error.address}
              </small>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  onChange={handleInput}
                  value={checkoutInput.city}
                  className="mt-1 p-2 w-full border rounded-md"
                />
                <small style={{ color: "red" }} className="text-danger">
                  {error.city}
                </small>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-700"
                >
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  onChange={handleInput}
                  value={checkoutInput.state}
                  className="mt-1 p-2 w-full border rounded-md"
                />
                <small style={{ color: "red" }} className="text-danger">
                  {error.state}
                </small>
              </div>
            </div>
            <div className="col-span-12">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="placeorder"
                  onClick={handleAddAddress}
                >
                  Add Address{" "}
                </button>
              </div>
            </div>
            {/*<div className="col-span-12">
          <div className="flex justify-end">
          <Link to="/orderplaced">
            <button
              type="button"
              className="placeorder"
              onClick={(e) => submitOrder(e, "cod")}
            >
              Place Order
            </button>
          </Link>

          </div>
          </div>*/}
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/3 text-sm xl:w-1/3 col-md-5 mt-5 ">
          <div className="w-full mb-5 " style={{ marginLeft: "auto" }}>
            <div className=" p-0">
              <div
                className="bg-white shadow rounded-lg p-4 justify-center flex mb-4"
                style={{
                  border: "1px solid #a5a5a5",
                  borderRadius: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                <div className="justiy-center ">
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "0px",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(0,179,149)",
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <HiOutlineShoppingCart
                        size={25}
                        style={{ marginTop: "-3px" }}
                      />{" "}
                      Cart ------{" "}
                      <FaRegAddressCard
                        size={25}
                        style={{
                          marginLeft: "3px",
                          marginRight: "4px",
                          marginTop: "-3px",
                        }}
                      />{" "}
                      Address{" "}
                    </span>
                    <span
                      style={{
                        color: "gray",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      ------{" "}
                      <MdOutlinePayment
                        size={25}
                        style={{
                          marginLeft: "3px",
                          marginRight: "1px",
                          marginTop: "-3px",
                        }}
                      />
                      Payment
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="bg-white shadow rounded-lg p-4"
                style={{ border: "1px solid #a5a5a5", borderRadius: "10px" }}
              >
                <div style={{ fontWeight: "600" }}>
                  Address -
                  <button
                    type="button"
                    className="placeorder01 ml-auto "
                    style={{ fontWeight: "400" }}
                    onClick={handleAddressButtonClick}
                  >
                    Select Address
                  </button>
                </div>
                {selectedAddress ? (
                  <p className="mt-2">{selectedAddress}</p>
                ) : (
                  <p style={{ color: "red", fontWeight: 600 }}>
                    No address selected
                  </p>
                )}{" "}
                <hr className="border-t border-gray-400 my-4" />
                {carts?.map((item) => (
                  <div className="flex items-center justify-between mb-2">
                    <span>{item?.product_name}</span>
                    <span>
                      <FormatPrice price={item.price * item.quantity} />
                    </span>
                  </div>
                ))}
                <hr className="border-t border-gray-400 my-4" />
                <div className="mb-4">
                  <div className="flex items-center justify-between mb-2">
                    <span>Subtotal</span>
                    <span>
                      <FormatPrice
                        price={totalPrice}
                        // price={carts.reduce(
                        //   (acc, cart) => acc + cart.price * cart.quantity,
                        //   0
                        // )}
                      />
                    </span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <span>{ totalPrice <= 400 ? "Standard Delivery" : "Delivery"}</span>
                    <span>
                    { totalPrice <= 400 ? 49 : "Free"}
                    {/* {selectedAddress && totalPrice <= 400 ? 49 : shipment ? shipment : "-"} */}
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-4 font-bold">
                  <span>Total (Tax incl.)</span>
                  <span>
                    <FormatPrice price={finalAmount} />
                  </span>
                </div>
                <div className="col-span-12">
                  <div className="flex justify-end">
                    {show && (
                      <div>
                        <div
                          style={{ justifyContent: "right", display: "flex" }}
                        >
                          {loading ? (
                            <button
                              type="button"
                              className="placeorder"
                              disabled
                            >
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="placeorder"
                              onClick={handlePlaceOrder}
                              disabled={!selectedAddress && !shipment}
                            >
                              {selectedAddress
                                ? "Place Order"
                                : "Select an Address "}
                            </button>
                          )}
                        </div>
                        {showDeliveryMessage && (
        <div style={{ paddingTop: "10px", color: "green" }}>
          To remove standard delivery charges, increase your cart value above Rs 400
        </div>
      )}
                      </div>
                    )}
                    {/* {show ? ( 
  <button
    type="button"
    className="placeorder"
    onClick={handlePlaceOrder}
    disabled={!selectedAddress && !shipment}
  >
    {selectedAddress ? "Place Order" : "Select an Address "}
  </button>
) : (
  <p>Failed to calculate shipment charge. Please try again later.</p>
)} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Address Modal */}
      {showAddressModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50 flex items-center justify-center ">
          <div className="bg-white p-8 m-5 max-w-2xl rounded">
            {userAddresses && userAddresses?.length > 0 ? (
              <>
                <h2 className="text-2xl font-semibold mb-6">
                  Select an Address
                </h2>
                <div
                  className="flex flex-col space-y-4"
                  style={{
                    border: "1px solid #d3d3d3",
                    padding: "15px",
                    borderRadius: "5px",
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  {userAddresses?.map((address) => (
                    <label
                      key={address?.id}
                      className="flex flex-row items-center space-x-2"
                    >
                      <div className="flex">
                        <input
                          type="checkbox"
                          checked={selectedAddress === address?.address}
                          onChange={() =>
                            handleAddressCheckboxChange(
                              address?.address,
                              address
                            )
                          }
                        />
                        <span className="px-2">{address?.address}</span>
                      </div>
                      <div
                        className="flex justify-end ml-auto"
                        style={{ marginLeft: "auto" }}
                      >
                        <RiDeleteBin6Line
                          size={20}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleDeleteAddress(address?.id)}
                        />
                      </div>
                    </label>
                  ))}
                </div>
                <div className="mt-8 flex justify-end">
                  <button
                    className="placeorder"
                    onClick={handleAddressModalClose}
                  >
                    Done
                  </button>
                </div>
              </>
            ) : (
              <>
                <h2 className="text-2xl font-semibold mb-6">
                  No saved address
                </h2>
                <div className="mt-8 flex justify-end">
                  <button
                    className="placeorder"
                    onClick={handleAddressModalClose}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default Checkout;
