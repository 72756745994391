import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import { auth, provider } from "./Google";
import { signInWithPopup } from "firebase/auth";
import {
  sendUserOTP,
  userRegister,
  verifyUserOTP,
} from "../../services/SignUpService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup() {
  const [value, setValue] = useState("");

  const handleClick = () => {
    signInWithPopup(auth, provider).then((data) => {
      setValue(data.user.email);
      localStorage.setItem("email", data.user.email);
    });
  };

  useEffect(() => {
    setValue(localStorage.getItem("email"));
  });
  const DEVICE_TYPE = "web";
  const DEVICE_ID = "";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [phoneNO, setPhoneNO] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [userID, setUserID] = useState("");
  const [OTP, setOTP] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const input1ref = useRef();
  const input2ref = useRef();
  const input3ref = useRef();
  const input4ref = useRef();
  const arr = [input1ref, input2ref, input3ref, input4ref];
  const navigate = useNavigate();

  const handleCreateAccount = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (!firstName) {
      toast.info("First name is required!");
      return;
    }

    if (!email) {
      toast.info("Email is required!");
      return;
    }

    const checkEmailExists = await checkEmailExistsAPI(email);
    if (checkEmailExists) {
      toast.error("Email already exists!");
      return;
    }

    if (!password) {
      toast.info("Password is required!");
      return;
    }

    if (!confirmPassword) {
      toast.info("Confirm password is required!");
      return;
    }

    if (confirmPassword !== password) {
      toast.info("Confirm password should be the same as password!");
      return;
    }

    const formData = {
      first_name: firstName,
      last_name: "Lastname",
      email: email,
      user_name: email,
      // user_name: userName,
      // phone_no: phoneNO,
      password: password,
      confirm_password: confirmPassword,
      device_id: DEVICE_ID,
      device_type: DEVICE_TYPE,
    };

    try {
      const { data } = await userRegister(formData);

      if (!data) return;
      else {
        if (data.result) {
          toast.success(data.msg);
          setUserID(data.data.user_id);
          sendOTP(data.data.user_id);
          setShowPopup(true);
        }

        if (!data.result && data.msg) {
          toast.info(data.msg);
        } else {
          toast.error(data.errors);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    }

    setFirstName("");
    setLastName("");
    setEmail("");
    setConfirmPassword("");
    setPassword("");
    setUserName("");
    setPhoneNO("");
  }

  // Placeholder function for checking if the email already exists
  async function checkEmailExistsAPI(email) {

    return false; // Placeholder; replace with actual implementation
  }

  async function sendOTP(userID) {
    const formData = new FormData();
    formData.append("email_or_user_id", userID);
    try {
      const { data } = await sendUserOTP(formData);
      if (!data) return;
      else {
        if (data.result) {
          // toast(data.msg);
        } else {
          toast(data.errors);
        }
      }
    } catch (e) {
      console.log(e);
      toast("Something went wrong");
    }
  }
  async function handleRendOTP() {
    const formData = new FormData();
    formData.append("email_or_user_id", userID);
    try {
      const { data } = await sendUserOTP(formData);
      if (!data) return;
      else {
        if (data.result) {
          toast(data.msg);
        }
        if (!data.result && data.msg) {
          toast(data.msg);
        } else {
          toast(data.errors);
        }
      }
    } catch (e) {
      console.log(e);
      toast("Something went wrong");
    }
  }

  function keyUp(e, index) {
    if (index >= 0 && index < 3)
      if (e.key == "Enter" || arr[index].current.value.length > 1) {
        arr[index + 1].current.focus();
        arr[index + 1].current.value = arr[index].current.value[1];
        arr[index].current.value = arr[index].current.value[0];
      }

    if (index == 3 && arr[index].current.value.length > 1)
      arr[index].current.value = arr[index].current.value[0];

    if (index > 0 && index < 4)
      if (e.key == "Backspace" && arr[index].current.value.length == 0) {
        arr[index - 1].current.focus();
      }
    setOTP(() => {
      return (
        input1ref.current.value +
        input2ref.current.value +
        input3ref.current.value +
        input4ref.current.value
      );
    });
  }

  async function handleOTPSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("otp", OTP);
    try {
      const { data } = await verifyUserOTP(formData);
      if (!data) return;
      else {
        if (data.result) {
          // toast(data.msg);
          navigate("/login");
        }
        if (!data.result && data.msg) {
          // toast(data.msg);
        } else {
          toast(data.errors);
        }
      }
    } catch (e) {
      console.log(e);
      toast("Something went wrong");
    }
    setShowPopup(false);
  }

  return (
    <>
      <div className="min-h-screen mt-32  flex items-center justify-center">
        <div className="bg-white border p-8 rounded-lg shadow-md w-full max-w-2xl">
          <h5
            className=" font-extrabold text-center mb-6 text-2xl uppercase"
            style={{
              fontFamily: "Tenor Sans, sans-serif",
              textDecoration: "underline #FBC44B ",
            }}
          >
            Create Account
          </h5>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-lg font-medium text-gray-700 mb-2"
                >
                  Full Name:
                </label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="Full name"
                  className="w-full text-black px-4 py-3 rounded-lg border border-gray-800 focus:ring focus:ring-indigo-500"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              
              <div>
                <label
                  htmlFor="email"
                  className="block text-lg font-medium text-gray-700 mb-2"
                >
                  Email:
                </label>
                <input
                  type="text"
                  id="email"
                  placeholder="Email"
                  className="w-full text-black px-4 py-3 rounded-lg border border-gray-800 focus:ring focus:ring-indigo-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            
              <div>
                <label
                  htmlFor="password"
                  className="block text-lg font-medium text-gray-700 mb-2"
                >
                  Password:
                </label>
                <div>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    className="w-full text-black px-4 py-3 rounded-lg border border-gray-800 focus:ring focus:ring-indigo-500"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                 
                </div>
              </div>
              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-lg font-medium text-gray-700 mb-2"
                >
                  Confirm Password:
                </label>
                <div className="">
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    id="confirmPassword"
                    placeholder="Confirm password"
                    className="w-full text-black px-4 py-3 rounded-lg border border-gray-800 focus:ring "
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  
                </div>
              </div>

              <br />
            </div>
            <button className="bg-[#952F8A] w-full text-xl text-white rounded-lg py-2 px-6 mt-4  font-medium mb-4">
              Create
            </button>
          </form>
        
          <div className="text-center mt-2">
            <p>
              Already have an account?{" "}
              <Link to="/login" className="text-indigo-600">
                Login
              </Link>
            </p>
          </div>
        </div>
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80 p-6">
            <div
              className="bg-white  mx-auto p-4 rounded-lg shadow-lg p-10"
              style={{ marginTop: "130px" }}
            >
              <div className="flex justify-end items-center">
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={closePopup}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <h1
                style={{ marginTop: 5 }}
                className="text-xl leading-tight text-center font-bold "
              >
                Email Verifications
              </h1>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleOTPSubmit(e);
                }}
                className="mt-6"
                action="#"
                method="POST"
              >
                <div>
                  <p className=" text-center">
                    An OTP has been sent to your registered Email
                  </p>
                  <div className="flex justify-center py-6 pb-2">
                    <input
                      type="text"
                      name=""
                      ref={input1ref}
                      style={{ borderBottom: "3px solid black" }}
                      className="w-16 mx-3 py-4  text-center border-0 text-4xl mt-2d"
                      autoFocus
                      autoComplete="off"
                      onKeyUp={(e) => {
                        keyUp(e, 0);
                      }}
                    />
                    <input
                      type="text"
                      name=""
                      id=""
                      ref={input2ref}
                      style={{ borderBottom: "3px solid black" }}
                      className="w-16 mx-3 py-4  text-center border-0 text-4xl mt-2d"
                      autoComplete="off"
                      onKeyUp={(e) => {
                        keyUp(e, 1);
                      }}
                    />
                    <input
                      type="text"
                      name=""
                      ref={input3ref}
                      style={{ borderBottom: "3px solid black" }}
                      className="w-16 mx-3 py-4 text-center border-0 text-4xl mt-2d"
                      autoComplete="off"
                      onKeyUp={(e) => {
                        keyUp(e, 2);
                      }}
                    />
                    <input
                      type="text"
                      name=""
                      ref={input4ref}
                      style={{ borderBottom: "3px solid black" }}
                      className="w-16 mx-3 py-4 text-center border-0 text-4xl mt-2d"
                      autoComplete="off"
                      onKeyUp={(e) => {
                        keyUp(e, 3);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full block bg-orange-400	 hover:bg-black focus:bg-black text-white tracking-widest px-4 py-3 mt-6"
                  >
                    CONTINUE
                  </button>
                  <div className="mt-4 text-sm text-gray-600">
                    <span>
                      Didn't receive the OTP?{" "}
                      <span
                        onClick={handleRendOTP}
                        className="text-black font-extrabold cursor-pointer"
                      >
                        Resend OTP
                      </span>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={20000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Signup;
