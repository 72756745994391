import { useQuery } from "@tanstack/react-query"
import { getStaticPageData } from "../../services/productService";
import { useParams } from "react-router-dom";
import IndexHeader from "./IndexHeader";

export default function StaticPage() {
    const params = useParams();
    const { data } = useQuery({
        queryKey: ["static_pages", params?.slug],
        queryFn: getStaticPageData,
    });
// console.log(data);
    const filterData = data?.filter((item) => item.slug === params?.slug)[0]

    return (
        <div className="index-section mt-24 lg:px-24 md:px-24">
            <div className="page-width">
                <IndexHeader indexHeaderName={filterData?.title} />
                {/* <div className='section-head text-center'>
                    <h2 className='section-header--title text-uppercase'>{filterData?.title}</h2>
                </div> */}
            </div>
            <div
                style={{ fontFamily: "Tenor Sans" }}
                className="p-5"
                dangerouslySetInnerHTML={{
                    __html: filterData?.description,
                }}
            />
        </div>
    )
}
