import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { useQuery } from "@tanstack/react-query";
import { addContact } from "../../../services/Users";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
function Contact() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [googleSocialLink, setGoogleSocialLink] = useState("");

  useEffect(() => {
    fetchData();
  }, []);
  
 useEffect(() => {
window.scrollTo(0,0);
 },[])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/getConfig`
      );
      setGoogleSocialLink(response.data.data.social_google_link);
      // console.log("link - ", googleSocialLink);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!name) {
      toast.info("Name is required!");
      return;
    }
    if (!email) {
      toast.info("Email is required!");
      return;
    }
    if (!phone) {
      toast.info("Phone No. is required!");
      return;
    }
    if (!message) {
      toast.info("Message is required!");
      return;
    }
    if (!recaptchaValue) {
      toast.info("Please complete the reCAPTCHA!");
      return;
    }

    const formData = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    try {
      const { data } = await addContact(formData);
      toast(data.msg);
      setShowPopup(true); // Set showPopup to true after successful form submission
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    }

    // Clear form fields
    setName("");
    setPhone("");
    setEmail("");
    setMessage("");
    setRecaptchaValue(null);
  }

  return (
    <>
      <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-VY1YL2WN1S"></script>
  <script>
    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-VY1YL2WN1S');
    `}
  </script>
  <script type="text/javascript">
                    {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "mdcd40ib4r");
                    `}
                </script>
        <meta charSet="utf-8" />
        <title>Kuku Namkeen | Contact Us</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <script>
                    {`
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '398356959855805');
                        fbq('track', 'PageView');
                    `}
                </script>
                <noscript>
                    {`
                        <img height="1" width="1" style="display:none"
                             src="https://www.facebook.com/tr?id=398356959855805&ev=PageView&noscript=1" />
                    `}
                </noscript>
      </Helmet>

      <div className="mt-28  px-4 md:px-8 text-center">
        <h5
          className="text-center  text-2xl uppercase"
          style={{
            fontFamily: "Tenor Sans, sans-serif",
            // textDecoration: "underline #FBC44B ",
          }}
        >
          Contact US
        </h5>
        <p className="mt-5">
          We are happy to hear from you! For any query, please write to us, and
          we will get back to you at the earliest.
        </p>
      </div>

      <div className="flex flex-wrap justify-center items-center gap-10  mt-6">
        {/* Contact Form */}
        <div className="w-full md:w-1/3 p-6">
          <form onSubmit={handleSubmit}>
            {/* ... (Your form fields) ... */}
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 font-bold mb-2"
              >
                Name:
              </label>
              <input
                type="text"
                placeholder="Enter Your Name"
                id="name"
                className="w-full border rounded py-2 px-3"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 font-bold mb-2"
              >
                Email:
              </label>
              <input
                type="text"
                id="phone number"
                placeholder="Enter Your Email"
                className="w-full border rounded py-2 px-3"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 font-bold mb-2"
              >
                Phone Number:
              </label>
              <input
                type="number"
                id="phone number"
                placeholder="Enter Your Phone Number"
                className="w-full border rounded py-2 px-3"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 font-bold mb-2"
              >
                Message:
              </label>
              <textarea
                id="message"
                placeholder="Write Your Message"
                className="w-full border rounded py-2 px-3"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>

            {/* Add your reCAPTCHA component here */}
            <div className="mb-4 flex justify-center">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={(value) => setRecaptchaValue(value)}
              />
            </div>
            <div className="flex justify-center">
            <button
              type="submit"
              className="bg-purple-800 text-white w-1/2 max-sm:w-1/2  px-4 py-2 rounded hover:bg-black hover:text-white justify-center"
            >
              SUBMIT
            </button></div>
            
          
          <div className="my-4 flex justify-center flex-col text-center">
          
            <p className="block text-gray-700 font-bold mb-2">
            Email ID:{" "}
              <span className="text-blue-500 hover:underline">
              kukunamkeen65@gmail.com
                            </span>
            </p>
            <p className="block text-gray-700 font-bold mb-2">
              Customer Care Timings:{" "}
              <span className="text-blue-500 hover:underline">
                10:00 AM To 6:00 PM
              </span>
            </p>
            <p className="block text-gray-700 font-bold mb-2">
            Phone Number:{" "}
              <span className="text-blue-500 hover:underline">
              +91 9427265661 / +91 8780266042
              </span>
            </p>
            
          </div>
          </form>
        </div>
        {/* Popup */}
        {showPopup && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-opacity-50 bg-gray-800"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            onClick={() => setShowPopup(false)}
          >
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {/* Your popup content */}
              <div className="bg-white p-8 md:p-12 shadow-md rounded-md text-center">
                <p className="text-xl md:text-2xl font-semibold mb-4">
                  We'll be in touch shortly!
                </p>
                <button
                  onClick={() => setShowPopup(false)}
                  className="mt-4 bg-purple-800 text-white px-6 py-3 rounded-md hover:bg-black hover:text-white"
                >
                  OK
                </button>
              </div>
              
            </div>
          </div>
        )}
        {/* Contact Information */}
        {/* <div className="w-full md:w-1/3 p-6">
          <div className="">
            <iframe
              title="Google Maps"
              src={googleSocialLink}
              width="100%"
              height="300"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="">
            <h2 className="text-2xl font-semibold">Address</h2>
            <p>
              Shed no.A/4,G.I.D.C Ph-1.Hirjimistri road, Jamnagar-361004
              (Gujarat) INDIA
            </p>
          </div>
          <div className="">
            <label
              htmlFor="email"
              className="block text-gray-700 font-bold mb-2"
            >
              Email ID:
            </label>
            <a
              href="mailto:support@kukunamkeen.com"
              className="text-blue-500 hover:underline"
            >
              support@kukunamkeen.com
            </a>
          </div>
          <div className="">
            <label
              htmlFor="phone"
              className="block text-gray-700 font-bold mb-2"
            >
              Phone Number:
            </label>
            <a href="tel:9709866551" className="text-blue-500 hover:underline">
              9709866551
            </a>
          </div>
          <div className="mb-4">
            <p className="block text-gray-700 font-bold mb-2">
              Customer Care Timings:{" "}
              <span className="text-blue-500 hover:underline">
                10:00 AM To 6:00 PM
              </span>
            </p>
          </div>
        </div> */}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={20000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Contact;
