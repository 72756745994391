import React, { useEffect, useState, Fragment } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getBannersSectionData,
  getCategorySectionData,
  getContentSectionData,
  getHomeManageData,
  getHotOffersData,
  getOffersData,
  getPerks,
  getTagwiseData,
} from "../../services/HomeManageService";
import Second from "../SecondSection/Second";
import Third from "../ThirdSection/Third";
import Fifth from "../FifthSection/Fifth";
import Seventh from "../SeventhSection/Seventh";
import Sixth from "../SixthSection/Sixth";
import Fourth from "../FourthSection/Fourth";
import BestSeller from "../BestSeller/Bestseller";
import Certificate from "../Certificate/Certificate";

const MainContent = () => {
  const { data: homeManageData } = useQuery({
    queryKey: ["homeManageData"],
    queryFn: getHomeManageData,
  });
 

  const { data: categorySection } = useQuery({
    queryKey: ["categorySection"],
    queryFn: getCategorySectionData,
  });

  const { data: bannerSectionData } = useQuery({
    queryKey: ["bannerSectionData"],
    queryFn: getBannersSectionData,
  });
  const { data: contentSectionData } = useQuery({
    queryKey: ["contentSectionData"],
    queryFn: getContentSectionData,
  });
  const { data: hotOffersData } = useQuery({
    queryKey: ["hotOffersData"],
    queryFn: getHotOffersData,
  });
  const { data: OffersData } = useQuery({
    queryKey: ["OffersData"],
    queryFn: getOffersData,
  });
  const { data: perksData } = useQuery({
    queryKey: ["perksData"],
    queryFn: getPerks,
  });
  const { data: tagwiseData } = useQuery({
    queryKey: ["tagwiseData"],
    queryFn: getTagwiseData,
  });

  const [category, setCategory] = useState(null);
  const [banner, setBanner] = useState(null);
  const [content, setContent] = useState(null);
  const [hotOffers, setHotOffers] = useState(null);
  const [offers, setOffers] = useState(null);
  const [perks, setPerks] = useState(null);
  const [tags, setTags] = useState(null);

  useEffect(() => {
    if (categorySection && homeManageData) {
      homeManageData?.map((item) => {
        if (item.block_type_name === "Categorywise") {
          const a = categorySection.find(
            (itm) => itm.id == parseInt(item.block_type_values)
          );

          setCategory(a);
        }
      });
    }
  }, [categorySection, homeManageData]);

  useEffect(() => {
    if (bannerSectionData && homeManageData) {
      homeManageData?.map((item) => {
        if (item.block_type_name === "staticbanner") {
          const a = bannerSectionData.find(
            (itm) => itm.id == parseInt(item.block_type_values)
          );
          setBanner(a);
        }
      });
    }
  }, [bannerSectionData, homeManageData]);

  useEffect(() => {
    if (contentSectionData && homeManageData) {
      homeManageData?.map((item) => {
        if (item.block_type_name === "contentwise") {
          const a = contentSectionData.find(
            (itm) => itm.content_id == parseInt(item.block_type_values)
          );
          setContent(a);
        }
      });
    }
  }, [contentSectionData, homeManageData]);
 

  useEffect(() => {
    if (hotOffersData && homeManageData) {
      homeManageData?.map((item) => {
        if (item.block_type_name === "Hotoffers") {
          const a = hotOffersData.find(
            (itm) => itm.id == parseInt(item.block_type_values)
          );
          setHotOffers(a);
        }
      });
    }
  }, [hotOffersData, homeManageData]);

  useEffect(() => {
    setOffers(OffersData);
  }, [OffersData, homeManageData]);

  useEffect(() => {
    setPerks(perksData);
  }, [perksData]);

  useEffect(() => {
    if (tagwiseData && homeManageData) {
      homeManageData?.map((item) => {
        if (item.block_type_name === "tagwise") {
          const a = tagwiseData.find(
            (itm) => itm.id == parseInt(item.block_type_values)
          );
          setTags(a);
        }
      });
    }
  }, [tagwiseData, homeManageData]);

  const [positionData, setPositionData] = useState([]);
  useEffect(() => {
    const position = [];
    const a = homeManageData?.forEach((item) => {
      if (position[item.position]) {
        position.push(item.block_type_name)
      }
      else {
        position[item.position] = item.block_type_name;
      }
    });
    setPositionData(position)
  }, [homeManageData]);
  // console.log(contentSectionData);
  
  return (
    <div>
      {positionData?.map((item, index) => (
        <Fragment key={`${item}-${index}`}>
          {item === "perks" && <Second perksData={perks} />}
          {item === "Categorywise" && (
            <Seventh category={category} />
          )}
          {/* {item === "contentwise" && (
            <Third content={contentSectionData} />
          )} */}
          {item === "contentwise" && (
            <Fifth content={contentSectionData} />
          )}
          {/* {item === "tagwise" && <Sixth tags={tags} />} */}
          {item === "tagwise" && <Fourth tags={tags} />}
          {/* {item === "staticbanner" && (
            <StaticBanner banner={banner} />
          )}  */}
        </Fragment>
      ))}
      <BestSeller/>
      <Certificate/>
    </div>
  );
};

export default MainContent;
